
import {
  defineComponent,
  getCurrentInstance,
  onMounted,
  ref,
  reactive,
  readonly,
  watch,
} from "vue";
import { useRoute, useRouter } from "vue-router";
import { ApiBase, ApiQuickSettlement } from "@/core/api";
import mixin from "@/mixins";
import { useI18n } from "vue-i18n";
import _ from "lodash";
import Swal from "sweetalert2/dist/sweetalert2.js";
import JwtService from "@/core/services/JwtService";
import { ElUpload } from "element-plus";
import fileDownload from "js-file-download";
import moment from "moment";
import {
  commonChangeDefaultDate,
  setModuleBCN,
} from "@/core/directive/function/common";
import PermissionCommon from "@/components/layout/PermissionCommon.vue";

export default defineComponent({
  name: "quick-settlement-information",
  components: { PermissionCommon },
  setup() {
    const { t } = useI18n();

    const route = useRoute();
    const router = useRouter();

    const {
      showValidateErrorMsg,
      showFormSubmitSuccessMsg,
      showServerErrorMsg,
    } = mixin();

    const loading = ref<boolean>(false);
    const formRef = ref<null | HTMLFormElement>(null);
    const submitButton = ref<HTMLElement | null>(null);
    const cancelButton = ref<HTMLElement | null>(null);

    const formData = ref({
      id: route.params.id,
      order_number: "",
      order_type: "",
      customer: {},
      customer_id: "",
      customer_name: "",
      order_status: "",
      status: "",
      order_amount: 0.0,
      order_currency: "EUR",
      pay_amount: 0.0,
      pay_currency: "EUR",
      distribution_model: "",
      due_date: null,
      comment: "",
      file_id: 0,
      file_name: "",
    });

    const options = ref({
      defaultFormData: {
        id: route.params.id,
        order_number: "",
        order_type: "",
        customer: {},
        customer_id: "",
        customer_name: "",
        order_status: "",
        status: "",
        order_amount: 0.0,
        order_currency: "EUR",
        pay_amount: 0.0,
        pay_currency: "EUR",
        distribution_model: "",
        due_date: null,
        comment: "",
        file_id: 0,
        file_name: "",
      },
      cancelButton: false,
      currency: [
        {
          label: "EUR",
          value: "EUR",
        },
        {
          label: "CNY",
          value: "CNY",
        },
      ],
      customer: [],
      customerLoading: false,
      type: [
        {
          label: t("quickSettlement.typeSale"),
          value: 1,
        },
        {
          label: t("quickSettlement.typeDeposit"),
          value: 2,
        },
      ],
      distribution_model: [
        {
          label: t("quickSettlement.distributionModelGrossProfitMode"),
          value: 1,
        },
        {
          label: t("quickSettlement.distributionModelTransactionMode"),
          value: 2,
        },
      ],
      order_status: new Map([
        [1, t("quickSettlement.orderStatusNew")],
        [2, t("quickSettlement.orderStatusProcessed")],
        [3, t("quickSettlement.orderStatusVoid")],
      ]),
      payment_status: [
        {
          label: t("quickSettlement.paymentStatusPending"),
          value: 1,
        },
        {
          label: t("quickSettlement.paymentStatusPaid"),
          value: 2,
        },
      ],
    });

    const rules = ref({
      order_type: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
      customer_id: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
      order_amount: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
      order_status: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
      pay_amount: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
      status: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
      distribution_model: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
      due_date: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
    });

    watch(
      () => formData.value.order_number,
      (newValue) => {
        formData.value.order_number = newValue.replace(/[^0-9,]/g, "");
      }
    );

    const getCustomerOptions = async (query: string) => {
      options.value.customerLoading = true;
      const { data } = await ApiBase.getCustomerSourceData({ name: query });
      options.value.customerLoading = false;
      if (data.code == 0) {
        options.value.customer = data.data;
      }
    };

    const debounceCustomerSearch = _.debounce(getCustomerOptions, 1000);

    const searchCustomerItems = (query: string) => {
      debounceCustomerSearch(query);
    };

    const statusClass = (val: any) => {
      let statusClass = "";

      switch (val) {
        case 1:
          statusClass = "badge-light-primary";
          break;
        case 2:
          statusClass = "badge-light-success";
          break;
        case 3:
          statusClass = "badge-light-danger";
          break;
        default:
          statusClass = "badge-white";
          break;
      }
      return statusClass;
    };

    const currentInstance: any = getCurrentInstance();
    const uploadUrl = ApiQuickSettlement.uploadQuickSettlementFile();
    const uploadRefs = reactive({
      file_id: ref<InstanceType<typeof ElUpload>>(),
    });
    const id = route.params.id;
    const removeFlag = ref(false);
    let childWin: any;

    const uploadDisabled = ref({
      file_id: false,
    });

    const uploadData = ref({
      file_id: [] as any[],
    });

    const uploadHeaders = ref({
      Authorization: `Bearer ${JwtService.getToken()}`,
    });

    const handleProgress = (e, file, fileList, attributes) => {
      uploadDisabled.value[attributes] = true;
    };

    const handleSuccess = (response, file, fileList, sign, data) => {
      uploadDisabled.value[sign] = false;
      if (response.code !== 0) {
        const refModal = currentInstance.ctx._.refs[`uploadRefs.${sign}`];
        if (uploadData.value[sign].length == 0) {
          refModal!.handleRemove(file, file.raw);
        } else {
          let params = {
            ...uploadData.value[sign][0],
            uid: moment().valueOf(),
          };
          refModal!.clearFiles();
          refModal!.handleStart(params);
        }
        Swal.fire({
          text: t("quickSettlement.uploadError"),
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: t("common.okGotIt"),
          customClass: {
            confirmButton: "btn btn-primary",
          },
        });
        return;
      }
      uploadData.value[sign] = fileList;
      data[sign] = response.data.file_id;
      data[sign.replace(/id/, "name")] = file.name;
    };

    const handleError = (err, file, fileList, attributes) => {
      if (err) {
        uploadDisabled.value[attributes] = false;
        Swal.fire({
          text: t("common.uploadErrors"),
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: t("common.okGotIt"),
          customClass: {
            confirmButton: "btn btn-primary",
          },
        });
      }
    };

    const handleExceed = (files, fileList, sign) => {
      const refModal = currentInstance.ctx._.refs[`uploadRefs.${sign}`];
      const file = files[0];
      refModal!.clearFiles();
      refModal!.handleStart(file);
      refModal!.submit();
    };

    const handleRemove = (file, fileList, sign, data, options, removeFlag) => {
      const refModal = currentInstance.ctx._.refs[`uploadRefs.${sign}`];
      // 上传文件超出限制会在页面上删除文件，这里重新将之前的文件回显
      if (removeFlag && data[sign]) {
        refModal!.handleStart(options[sign][0]);
        return;
      }
      data[sign] = 0;
      data[sign.replace(/id/, "name")] = "";
    };

    const handleDeleteUpload = (sign, data) => {
      const refModal = currentInstance.ctx._.refs[`uploadRefs.${sign}`];
      if (uploadData.value[sign].length > 0) refModal!.clearFiles();
      data[sign] = 0;
      data[sign.replace(/id/, "name")] = "";
    };

    const handlePreview = (sign, form) => {
      const name = sign;
      uploadDisabled.value[sign] = true;
      ApiBase.getFile({
        id: form[sign],
      })
        .then(({ data }) => {
          uploadDisabled.value[sign] = false;
          fileDownload(data, formData.value.file_name);
          // let fileURL;
          // // var blob = new Blob([data], { type: "application/pdf" });
          // if (window.webkitURL != undefined) {
          //   // webkit or chrome
          //   fileURL = window.webkitURL.createObjectURL(data);
          // } else if (window.URL != undefined) {
          //   // mozilla(firefox)
          //   fileURL = window.URL.createObjectURL(data);
          // }
          // // let url = encodeURIComponent(fileURL);
          // childWin = window.open(
          //   fileURL,
          //   name,
          //   "height=700, width=600, top=10, left=10, toolbar=no, menubar=no, scrollbars=no, resizable=no,location=no, status=no"
          // );
          // childWin.onload = function () {
          //   // childWin.print();
          //   if (window.webkitURL != undefined) {
          //     // webkit or chrome
          //     window.webkitURL.revokeObjectURL(fileURL);
          //   } else if (window.URL != undefined) {
          //     // mozilla(firefox)
          //     window.URL.revokeObjectURL(fileURL);
          //   }
          // };
        })
        .catch((error) => {
          uploadDisabled.value[sign] = false;
          console.log(error);
        });
    };

    const handleBeforeUpload = (file) => {
      const limit = 1024 * 1024 * 5;
      if (file.size > limit) {
        Swal.fire({
          text: t("common.uploadSizeErrors"),
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: t("common.okGotIt"),
          customClass: {
            confirmButton: "btn btn-primary",
          },
        }).then(() => {
          removeFlag.value = false;
        });
        removeFlag.value = true;
        return false;
      }
      return true;
    };

    const getTaggingData = async () => {
      const { data } = await ApiBase.getTaggingData({
        short_key: ["sales_order_distribution_mode"],
      });
      if (data.code == 0) {
        options.value.distribution_model =
          data.data.sales_order_distribution_mode.items;
      }
    };

    const getShowInfo = async () => {
      const { data } = await ApiQuickSettlement.getQuickSettlementInfo({
        id: route.params.id,
      });
      if (data.code == 0) {
        formData.value = data.data;
        if (formData.value.file_id != 0) {
          uploadData.value.file_id = [
            {
              name: formData.value.file_name,
              url: "",
            },
          ];
        }
        Object.keys(options.value.defaultFormData).forEach((item) => {
          options.value.defaultFormData[item] = data.data[item];
        });
        getCustomerOptions(data.data.customer_id);
      }
    };

    const getFromInfo = async () => {
      loading.value = true;
      Promise.all([getShowInfo()])
        .then(() => {
          loading.value = false;
        })
        .catch((error) => {
          console.log(error);
        });
    };

    const cancelQuickSettlement = () => {
      Swal.fire({
        text: t("quickSettlement.voidTip"),
        icon: "warning",
        showCancelButton: true,
        buttonsStyling: false,
        confirmButtonText: t("common.yes"),
        cancelButtonText: t("common.no"),
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-danger",
        },
      }).then(async (result) => {
        if (result.isConfirmed) {
          if (cancelButton.value) {
            cancelButton.value.setAttribute("data-kt-indicator", "on");
          }
          options.value.cancelButton = true;
          const { data } = await ApiQuickSettlement.updateQuickSettlement({
            ...options.value.defaultFormData,
            order_status: 3,
          });
          cancelButton.value?.removeAttribute("data-kt-indicator");
          options.value.cancelButton = false;
          if (data.code == 0) {
            showFormSubmitSuccessMsg(() => {
              getFromInfo();
            });
          } else {
            showServerErrorMsg(data);
          }
        }
      });
    };

    const submit = () => {
      if (!formRef.value) {
        return;
      }
      formRef.value.validate((valid) => {
        if (valid) {
          if (loading.value) return;
          loading.value = true;
          if (submitButton.value) {
            submitButton.value.setAttribute("data-kt-indicator", "on");
          }
          ApiQuickSettlement.updateQuickSettlement(formData.value)
            .then(({ data }) => {
              loading.value = false;
              submitButton.value?.removeAttribute("data-kt-indicator");
              if (data.code == 0) {
                showFormSubmitSuccessMsg(() => {
                  getFromInfo();
                });
              } else {
                showServerErrorMsg(data);
              }
            })
            .catch((error) => {
              console.log(error);
            });
        } else {
          showValidateErrorMsg();
          return false;
        }
      });
    };

    const backToList = () => {
      router.push("/quick-settlement");
    };

    onMounted(() => {
      setModuleBCN(t, route, router);
      getFromInfo();
    });

    return {
      t,
      commonChangeDefaultDate,
      loading,
      options,
      formData,
      formRef,
      rules,
      cancelButton,
      submitButton,
      searchCustomerItems,
      statusClass,
      getShowInfo,
      getFromInfo,
      backToList,
      submit,
      cancelQuickSettlement,
      uploadRefs,
      uploadHeaders,
      id,
      uploadUrl,
      uploadDisabled,
      uploadData,
      handleProgress,
      handleSuccess,
      handleError,
      handleExceed,
      handleRemove,
      handleDeleteUpload,
      handleBeforeUpload,
      handlePreview,
    };
  },
});
